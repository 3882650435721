.logo-container {
  padding: 20px 0;
}

.logo-container > .logo-image {
  display: block;
  margin: 0 auto;
  width: 140px;
}

.logo-image.is-collapsed {
  width: 35px;
}

.logo__is-collapsed :not(.logo-not-letter) {
  width: 12px;
  display: none;
}

.logo-primary-color {
  fill: #ffffff;
}

.logo-secondary-color {
  fill: #ffffff;
  fill-opacity: 0.8;
}

.ant-table-thead .ant-table-cell {
  border-bottom-color: #b22330 !important;
}

.ant-table-row:nth-child(odd) {
  background-color: #f7f7f7;
}

.ant-card-head-title {
  font-weight: 400;
}

.exam-descriptions table {
  width: auto;
  min-width: 100%;
}

.qt-checkbox-group-vertical {
  display: flex;
  flex-direction: column;
  margin-top: 4px;
}

.qt-checkbox-group-vertical .ant-checkbox-wrapper {
  margin-left: 0 !important;
}

.qt-timeline .ant-timeline-item-label {
  inset-block-start: -7px !important;
  inset-inline-start: calc(50% + 14px) !important;
}

.qt-timeline .ant-timeline-item-content {
  width: calc(50% - 14px) !important;
}

.ant-table-row.qt-deleted-row {
  opacity: 0.8;
  text-decoration: line-through;
}